@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* antd overrides */
  .ant-form-item-label {
    @apply font-[600];
  }
  .ant-form-item-explain-error {
    @apply text-[12px];
  }
  .ant-form-item {
    @apply mb-[17px];
  }

  .bg-gradient-blue {
    @apply bg-gradient-to-r from-cyan-500 to-blue-500;
  }

  .bg-gradient-purple {
    @apply bg-gradient-to-r from-purple-400 to-purple-600;
  }
}
