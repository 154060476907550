*,
::before,
::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: #fff;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeSpeed;
}

ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
:focus {
  outline: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}
.app {
  overflow: hidden;
}
button {
  cursor: pointer;
}
figure {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto;
  margin: 0.5cm;
}

.ant-table th.ant-table-cell {
  font-size: 12px !important;
  font-weight: 600;
}
.ant-col > label {
  font-size: 12px !important;
  font-weight: 600;
}

/* scrollbar */
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #dedede;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #ddd;
  border: solid 3px transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #ADB5BD;
  border: solid 3px transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
